<template>
  <div class="bread-crumbs">
    <div class="bread-crumbs__wrapper">
      <Row>
        <router-link :key="item.id"
                     :style="{color: params.color, borderRightColor: params.color}"
                     :exact-active-class="'bread-crumbs__active'"
                     :to="item.href"
                     class="bread-crumbs__link"
                     v-for="item in params.navigation">{{item.title}}
        </router-link>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    params: {
      required: true,
    },
  },
};
</script>
